<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('sale.基本信息') }}</div>
      <van-collapse-item :title="$t('sale.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('sale.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.发货单号') }}</h5>
          <p>{{ ruleForm.deliveryNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.状态') }}</h5>
          <p>{{ ruleForm.status | setDict('SALE_CONTRACT_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.发货日期') }}</h5>
          <p>{{ ruleForm.deliveryDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务类型') }}</h5>
          <p>{{ ruleForm.contractCategory | setDict('CONTRACT_CATEGORY') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售合同号') }}</h5>
          <p>{{ ruleForm.contractNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.提货方式') }}</h5>
          <p>{{ ruleForm.deliveryType | setDict('DELIVERY_MODE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否工厂装柜') }}</h5>
          <p>{{ ruleForm.isFactoryPacked | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售业务员') }}</h5>
          <p>{{ ruleForm.bizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售业务部门') }}</h5>
          <p>{{ ruleForm.saleBizDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售合同类型') }}</h5>
          <p>{{ ruleForm.saleContractType | setDict('SALE_TRADE_CONTRACT_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售客户') }}</h5>
          <p>{{ ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.价格条款') }}</h5>
          <p>{{ ruleForm.priceTerms | setDict('PRICE_TERMS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否赊销') }}</h5>
          <p>{{ ruleForm.isSoldOnCredit | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.发货库房') }}</h5>
          <p>{{ ruleForm.warehouseName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.币种') }}</h5>
          <p>{{ ruleForm.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.原币发货金额') }}</h5>
          <p>{{ ruleForm.deliveryAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对人民币汇率') }}</h5>
          <p>{{ ruleForm.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.人民币发货金额') }}</h5>
          <p>{{ ruleForm.deliveryAmountRmb | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.应收开始日期') }}</h5>
          <p>{{ ruleForm.receivableStartDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.应收账龄') }}</h5>
          <p>{{ ruleForm.agingAccountReceivable }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.应收金额') }}</h5>
          <p>{{ ruleForm.receivableAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.采购入库号') }}</h5>
          <p>{{ ruleForm.invWarehouseNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否寄售业务') }}</h5>
          <p>{{ ruleForm.isConsignmentBusiness | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.采购合同号') }}</h5>
          <p>{{ ruleForm.purContractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.开票总金额') }}</h5>
          <p>{{ ruleForm.totalInvoiceAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.备注') }}</h5>
          <p>{{ ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item v-show="false" :title="$t('sale.收货信息')" name="2" v-if="ruleForm.deliveryType === '自提' || ruleForm.deliveryType === '3'">
        <div v-for="(item, index) in this.ruleForm.deliveryInfoList" :key="index"
             style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('sale.提货人') }}:</h5>
            <p>{{ item.deliveryPerson}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.提货人电话') }}:</h5>
            <p>{{ item.deliveryMobile}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.提货人身份证号') }}:</h5>
            <p>{{ item.deliveryCert}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.提货车号') }}:</h5>
            <p>{{ item.deliveryVehicleNo}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item v-show="false" :title="$t('sale.发货列表')" name="3" v-if="ruleForm.deliveryType === '配送'">
        <div v-for="(item, index) in this.ruleForm.deliveryInfoList" :key="index"
             style="border-bottom: 1px solid #e6e6e6;padding-bottom: 5px">
          <div class="items">
            <h5>{{ $t('sale.收货联系人') }}:</h5>
            <p>{{ item.consignor}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.收货联系人电话') }}:</h5>
            <p>{{ item.consignorMobile}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.收货联系人身份证') }}:</h5>
            <p>{{ item.consignorIdCard}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.收货单位') }}:</h5>
            <p>{{ item.consignorCompany}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.国家') }}:</h5>
            <p>{{ item.country}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.省') }}:</h5>
            <p>{{ item.province}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.市') }}:</h5>
            <p>{{ item.city}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.详细地址') }}:</h5>
            <p>{{ item.consignorAddress}}</p>
          </div>
        </div>
      </van-collapse-item>
      <van-collapse-item v-show="false" :title="$t('sale.发货商品')" name="4">
        <div v-for="(item, index) in ruleForm.commodityDetailList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.采购合同号') }}:</h5>
            <p>{{ item.purCustomerContractNo}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品编码') }}:</h5>
            <p>{{ item.materialCode}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品名称') }}:</h5>
            <p>{{ item.materialName}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品分类') }}:</h5>
            <p>{{ item.commodityCategoryName}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.规格型号') }}:</h5>
            <p>{{ item.materialModel}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品组') }}:</h5>
            <p>{{ item.productGroup | setDict('productGroup')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品线') }}:</h5>
            <p>{{ item.productLine | setDict('PRODUCTLINE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品类型') }}:</h5>
            <p>{{ item.productType | setDict('PRODUCT_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.产品类型') }}-二级分类:</h5>
            <p>{{ item.secondProductType | setDict('PRODUCT_SECOND_TYPE')}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.仓库') }}:</h5>
            <p>{{ item.warehouseName}}</p>
          </div>
          <div class="items">
            <h5>SN:</h5>
            <p>{{ item.sn}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.数量') }}:</h5>
            <p>{{ item.quantity}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.单位') }}:</h5>
            <p>{{ item.unit}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.含税单价') }}:</h5>
            <p>{{ item.priceIncludingTax}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.含税金额') }}:</h5>
            <p>{{ item.amountIncludingTax}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.增值税税率') }}:</h5>
            <p>{{ item.vatRate}}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.增值税税额') }}:</h5>
            <p>{{ item.vatAmount}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.不含税金额') }}:</h5>
            <p>{{ item.amountExcludeTax}}</p>
          </div>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.附件') }}</div>
      <van-collapse-item :title="$t('sale.附件信息')" name="4">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.系统信息') }}</div>
      <van-collapse-item :title="$t('sale.系统信息')" name="5">
        <div class="items">
          <h5>{{ $t('sale.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.制单人') }}</h5>
          <p>{{ this.ruleForm.createUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        attachmentList: [],
        functionalCurrency: '',
        loading: false,
        finished: false,
        activeNames: '1',
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        woCode: 'saleTradeDelivery',
        processInstanceName: '销售发货',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
        ruleForm: {
          id: '',
          deliveryNumber: '',
          status: 'DRAFT',
          isAClassCustomer: '',
          effect: 'N',
          isSoldOnCredit: '',
          bizUserId: '',
          bizUserName: '',
          customerId: '',
          customerName: '',
          customerCode: '',
          receivedAmount: '',
          warehouseCode: '',
          warehouseName: '',
          purContractNo: '',
          expandSharing: '',
          expandSharingName: '',
          saleArea: '',
          deliveryDate: '',
          expectedCollectionDate: '',
          taxType: '',
          contractIssuanceDatabase: '',
          remark: '',
          stockType: '',
          contractNumber: '',
          contractId: '',
          deliveryAmount: '',
          currency: '',
          receiveOrg: '',
          receiveAddr: '',
          receiveVehicleNo: '',
          receiver: '',
          receiveTel: '',
          deliveryType: '',
          settleType: '',
          transportInvoice: '',
          deliveryPerson1: '',
          deliveryPerson1Cert: '',
          deliveryPerson1Mobile: '',
          deliveryPerson2: '',
          deliveryPerson2Cert: '',
          deliveryPerson2Mobile: '',
          deliveryPerson3: '',
          deliveryPerson3Cert: '',
          deliveryPerson3Mobile: '',
          orgId: '',
          deptId: '',
          orderStatus: '',
          processInstanceId: '',
          createUserName: '',
          lastmodifiedUserName: '',
          deliveryTotalQuantity: '',
          amountExcludeTax: '',
          lineList: [],
          paymentList: [],
          deliveryInfoList: [],
          consignorInfoList: [],
          saleCommodityDetailList: [],
          commodityDetailList: [],
          /**
           * 收款客户名称
           */
          receivedCustomerName: '',
          // 销售合同类型
          saleContractType: '',
          unionType: '',
          orderState: '',
          bizUserMobile: '',
          finalCustomerIndustry: ' ',
          finalCustomerIndustryDetail: '',
          finalCustomerArea: '',
          secondLevelAgent: '',
          secondLevelAgentId: '',
          secondLevelAgentCode: '',
          signingSecondaryAgent: '',
          signingSecondaryAgentId: '',
          signingSecondaryAgentCode: '',
          thirdLevelAgent: '',
          thirdLevelAgentId: '',
          thirdLevelAgentCode: '',
          expectCollectionDate: '',
          purchaseManName: '',
          purchaseManId: '',
          deliveryQuantity: 0,
          sendEmail: '',
          sendDate: '',
          receiveMail: '',
          warehouseHeaderId: '',
          warehouseOutSignStatus: '',
          customerContractNo: '',
          saleContractInfo: {},
        },
      };
    },
    props: {},
    methods: {
      /**
       * 根据文件id和文件名生成预览链接
       * @param id 附件id
       * @param fileName 附件名
       * @returns {string}
       */
      handleFileView (id, fileName) {
        this.$FilePrvwUtils.openFile(id,fileName);
      },
      downloadAttachment(id, fileName) {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/download",
          data: {
            "entity": {
              "id": id
            }
          },
          responseType: "arraybuffer"
        }).then(res => {
          // 成功回调
          var headers = res.headers;
          var blob = new Blob([res.data], {
            type: headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        });
      },
      getAttachmentList() {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/list",
          data: {
            "entity": {
              "bizId": this.ruleForm.id
            }
          }
        }).then(res => {
          let backData = res.data;
          if (backData) {
            this.attachmentList = backData;
          }
        });
      },
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      backfilleditData(id) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/sale/delivery/trade/header/view',
          data: {
            entity: {
              id: id
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.deliveryNumber, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('sale.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.deliveryNumber, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/sale/delivery/trade/header/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('sale.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.deliveryNumber, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/sale/delivery/trade/header/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/sale/delivery/trade/header/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('sale.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/sale/delivery/trade/header/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {},
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
        }
      }
      if (this.ruleForm.id) {
        this.backfilleditData(this.ruleForm.id);
      }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      },
      formatAmount(v, decimal = 2, isCovering = true) {
        return util.formatAmount(v, decimal, isCovering);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
